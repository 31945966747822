<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="session_id">{{ $t('detailed_pos_shifts_transactions_report.session_id') }}</label>
                <input type="text" id="session_id" v-model="filters.f_session_ids" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="stores_ids">{{ $t('detailed_pos_shifts_transactions_report.store') }}</label>
                <multiselect id="stores_ids"
                             v-model="stores_ids"
                             :multiple="false"
                             @input="getIds('stores')"
                             :options="stores"
                             :placeholder="$t('detailed_pos_shifts_transactions_report.store')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="device_id">{{ $t('pos_session.device') }}</label>
                <multiselect id="device_id"
                             v-model="device"
                             :multiple="false"
                             @input="getIds('device')"
                             :options="devices"
                             :placeholder="$t('pos_session.device')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="shift_id">{{ $t('pos_session.shift') }}</label>
                <multiselect id="shift_id"
                             v-model="shift"
                             :multiple="false"
                             @input="getIds('shift')"
                             :options="shifts"
                             :placeholder="$t('pos_session.shift')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('detailed_pos_shifts_transactions_report.branch') }}</label>
                <multiselect v-model="branches_ids"
                             :placeholder="$t('detailed_pos_shifts_transactions_report.branch')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="false"
                             @input="getIds('branches')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
<!--              <div class="form-group col-md-6 mt-2 mb-2">-->
<!--                <label for="inventory_id">{{ $t('detailed_pos_shifts_transactions_report.inventory') }}</label>-->
<!--                <multiselect v-model="inventories_ids"-->
<!--                             :placeholder="$t('detailed_pos_shifts_transactions_report.inventory')"-->
<!--                             label="name"-->
<!--                             track-by="id"-->
<!--                             :options="inventories"-->
<!--                             @input="getIds('inventories')"-->
<!--                             :multiple="true"-->
<!--                             :taggable="false"-->
<!--                             :show-labels="false"-->
<!--                             :show-no-options="false"-->
<!--                             :show-no-results="false">-->
<!--                </multiselect>-->
<!--              </div>-->

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('currency') }}</label>
                <select id="currency_id" v-model="filters.f_currencies_ids" class="custom-select" name="">
                  <option v-for="row in currencies" :key="row.id" :value="row.id">{{ row.name }}</option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{$t('order_by')}}</label>
                <select name="" id="order_by" v-model="filters.order_by" class="custom-select">
                  <option value="">{{$t('all')}}</option>
                  <option value="asc">{{$t('asc')}}</option>
                  <option value="desc">{{$t('desc')}}</option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">
                  {{ $t('detailed_pos_shifts_transactions_report.from_date') }}
                </label>
                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('detailed_pos_shifts_transactions_report.to_date') }}</label>
                <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
              </div>
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button  @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_date')}}</b-button>
                    <b-button  @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_month')}}</b-button>
                    <b-button  @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_year')}}</b-button>
                    <b-button  @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button  @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.yesterday')}}</b-button>
                    <b-button  @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_month')}}</b-button>
                    <b-button  @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_year')}}</b-button>
                  </b-button-group>

                </div>
              </div>
              <div class="form-group d-inline-flex col-md-6 mt-10">
                <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{ $t('display_report') }}</b-button>
                <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}</b-button>

              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <div class="card card-custom">
      <div class="card-body">
        <div>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('details')" active>
                <div class="row">

              <div class="col-12 mt-10" id="summaryTableTitle">
                <h4 class="text-center">{{ $t('MENU.detailed_pos_shifts_transactions_report') }}</h4>
                <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span></h5>
              </div>
              <div class="col-12 mt-10 d-flex justify-content-end">
                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                  <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                    <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                  </button>

                  <button class="dropdown-item" @click="printData('summaryTable')">
                    <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                  </button>
                  <button class="dropdown-item" @click="getAllDataForExport('sectionForExport', 'excel')">
                    <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                  </button>

                </b-dropdown>
              </div>
              <div class="col-12 mt-10">
                <div class="table-responsive" style="width: 100%;overflow-x: scroll !important;">
                  <table class="table table-bordered text-center" id="summaryTable">
                    <thead>
                    <tr>
                      <td>#</td>
                      <th v-if="filters.group_by != 'session'">{{ $t('detailed_pos_shifts_transactions_report.session_code') }}</th>
                      <th v-if="filters.group_by != 'shift'">{{ $t('detailed_pos_shifts_transactions_report.shift') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.session_start_time') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.session_end_time') }}</th>
                      <th v-if="filters.group_by != 'treasury_user'">{{ $t('detailed_pos_shifts_transactions_report.treasury_employee') }}</th>
                      <th v-if="filters.group_by != 'session_user'">{{ $t('detailed_pos_shifts_transactions_report.confirmed_by') }}</th>
                      <th v-if="filters.group_by != 'branch'">{{ $t('detailed_pos_shifts_transactions_report.branch') }}</th>
                      <th v-if="filters.group_by != 'store'">{{ $t('detailed_pos_shifts_transactions_report.store') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.sales') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.refunds') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.net') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.net_cash') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.net_non_cash') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.forward') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.total_cash_receipt') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.total_cash_disbursement') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.total_cash_received') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.total_non_cash_received') }}</th>
                      <th>{{ $t('detailed_pos_shifts_transactions_report.total_received') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                   <template v-for="(row, index) in data">
                     <tr>
                       <td colspan="19" style="text-align: start !important;">{{ row.label }}</td>
                     </tr>
                     <tr v-for="(_row, _index) in row.items" :key="'vvv'+index+_index">
                       <td>{{ _index+1 }}</td>
                       <td v-if="filters.group_by != 'session'">{{ _row.session_id }}</td>
                       <td v-if="filters.group_by != 'shift'">{{ _row.shift_name }}</td>
                       <td>{{ _row.opened_date }}</td>
                       <td>{{ _row.closed_date }}</td>
                       <td v-if="filters.group_by != 'treasury_user'">{{ _row.treasury_user_name }}</td>
                       <td v-if="filters.group_by != 'session_user'">{{ _row.session_user_name }}</td>
                       <td v-if="filters.group_by != 'branch'">{{ _row.branch_name }}</td>
                       <td v-if="filters.group_by != 'store'">{{ _row.store_name }}</td>

                       <td>{{ _row.total_sales_invoice }}</td>
                       <td>{{ _row.total_returned_invoice }}</td>
                       <td>{{ _row.total }}</td>
                       <td>{{ _row.net_sale_cash_total_amount }}</td>
                       <td>{{ _row.net_sale_none_cash_total_amount }}</td>
                       <td>{{ _row.total_sales_remaining_amount }}</td>
                       <td>{{ _row.net_cash_received_amount }}</td>
                       <td>{{ _row.net_cash_expense_amount }}</td>
                       <td>{{ _row.net_cash_received_total_amount }}</td>
                       <td>{{ _row.net_none_cash_received_total_amount }}</td>
                       <td>{{ _row.total_received_amount }}</td>
                     </tr>
                     <tr>
                       <td colspan="8" style="text-align: start !important;">{{ $t('detailed_pos_shifts_transactions_report.summation') }}</td>
                       <td>{{ row.total_sales_invoice }}</td>
                       <td>{{ row.total_returned_invoice }}</td>
                       <td>{{ row.total }}</td>
                       <td>{{ row.net_sale_cash_total_amount }}</td>
                       <td>{{ row.net_sale_none_cash_total_amount }}</td>
                       <td>{{ row.total_sales_remaining_amount }}</td>
                       <td>{{ row.net_cash_received_amount }}</td>
                       <td>{{ row.net_cash_expense_amount }}</td>
<!--                       <td>{{ row.total_received_amount }}</td>-->
                       <td>{{ row.net_cash_received_total_amount }}</td>
                       <td>{{ row.net_none_cash_received_total_amount }}</td>
                       <td>{{ row.total_received_amount }}</td>
                     </tr>

                   </template>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td colspan="8" style="text-align: start !important;">{{ $t('detailed_pos_shifts_transactions_report.summation') }}</td>
                      <td>{{ dataTotal.total_sales_invoice }}</td>
                      <td>{{ dataTotal.total_returned_invoice }}</td>
                      <td>{{ dataTotal.total }}</td>
                      <td>{{ dataTotal.net_sale_cash_total_amount }}</td>
                      <td>{{ dataTotal.net_sale_none_cash_total_amount }}</td>
                      <td>{{ dataTotal.total_sales_remaining_amount }}</td>
                      <td>{{ dataTotal.net_cash_received_amount }}</td>
                      <td>{{ dataTotal.net_cash_expense_amount }}</td>
<!--                      <td>{{ dataTotal.total_received_amount }}</td>-->
                      <td>{{ dataTotal.net_cash_received_total_amount }}</td>
                      <td>{{ dataTotal.net_none_cash_received_total_amount }}</td>
                      <td>{{ dataTotal.total_received_amount }}</td>

                    </tr>
                    </tfoot>
                  </table>
                </div>

              </div>
            </div>
              </b-tab>
              <template #tabs-end>
                <li class="nav-item  d-flex my-auto">
                  <select id="" v-model="filters.group_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none" name="">
                    <option :value="null" disabled>{{ $t('select_filter') }}</option>
                    <option value="session">{{ $t('detailed_pos_shifts_transactions_report.session') }}</option>
                    <option value="shift">{{ $t('detailed_pos_shifts_transactions_report.shift') }}</option>
                    <option value="treasury_user">{{ $t('detailed_pos_shifts_transactions_report.treasury_employee') }}</option>
                    <option value="session_user">{{ $t('detailed_pos_shifts_transactions_report.session_user') }}</option>
                    <option value="branch">{{ $t('detailed_pos_shifts_transactions_report.branch') }}</option>
                    <option value="store">{{ $t('detailed_pos_shifts_transactions_report.store') }}</option>
                  </select>

                </li>
              </template>
            </b-tabs>
          </div>
        </div>
        <!-- End Filter -->
        <div class="col-12 text-center" v-if="page">
          <button class="btn btn-warning" @click="loadMore">
            <p class="mb-0">
              <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
              {{ $t('load_more') }}
            </p>
          </button>
        </div>
      </div>
    </div>
    <!--end::customer-->

    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered text-center" id="sectionForExport">
          <thead>
          <tr>
            <td>#</td>
            <th v-if="filters.group_by != 'session'">{{ $t('detailed_pos_shifts_transactions_report.session_code') }}</th>
            <th v-if="filters.group_by != 'shift'">{{ $t('detailed_pos_shifts_transactions_report.shift') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.session_start_time') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.session_end_time') }}</th>
            <th v-if="filters.group_by != 'treasury_user'">{{ $t('detailed_pos_shifts_transactions_report.treasury_employee') }}</th>
            <th v-if="filters.group_by != 'session_user'">{{ $t('detailed_pos_shifts_transactions_report.confirmed_by') }}</th>
            <th v-if="filters.group_by != 'branch'">{{ $t('detailed_pos_shifts_transactions_report.branch') }}</th>
            <th v-if="filters.group_by != 'store'">{{ $t('detailed_pos_shifts_transactions_report.store') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.sales') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.refunds') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.net') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.net_cash') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.net_non_cash') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.forward') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.total_cash_receipt') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.total_cash_disbursement') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.total_cash_received') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.total_non_cash_received') }}</th>
            <th>{{ $t('detailed_pos_shifts_transactions_report.total_received') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataForExport">
            <tr>
              <td colspan="20" style="text-align: start !important;">{{ row.label }}</td>
            </tr>
            <tr v-for="(_row, _index) in row.items" :key="'ccc'+index+_index">
              <td>{{ _index+1 }}</td>
              <td v-if="filters.group_by != 'session'">{{ _row.session_id }}</td>
              <td v-if="filters.group_by != 'shift'">{{ _row.shift_name }}</td>
              <td>{{ _row.opened_date }}</td>
              <td>{{ _row.closed_date }}</td>
              <td v-if="filters.group_by != 'treasury_user'">{{ _row.treasury_user_name }}</td>
              <td v-if="filters.group_by != 'session_user'">{{ _row.session_user_name }}</td>
              <td v-if="filters.group_by != 'branch'">{{ _row.branch_name }}</td>
              <td v-if="filters.group_by != 'store'">{{ _row.store_name }}</td>

              <td>{{ _row.total_sales_invoice }}</td>
              <td>{{ _row.total_returned_invoice }}</td>
              <td>{{ _row.total }}</td>
              <td>{{ _row.net_sale_cash_total_amount }}</td>
              <td>{{ _row.net_sale_none_cash_total_amount }}</td>
              <td>{{ _row.total_sales_remaining_amount }}</td>
              <td>{{ _row.net_cash_received_amount }}</td>
              <td>{{ _row.net_cash_expense_amount }}</td>
              <td>{{ _row.net_cash_received_total_amount }}</td>
              <td>{{ _row.net_none_cash_received_total_amount }}</td>
              <td>{{ _row.total_received_amount }}</td>
            </tr>
            <tr>
              <td colspan="8" style="text-align: start !important;">{{ $t('detailed_pos_shifts_transactions_report.summation') }}</td>
              <td>{{ row.total_sales_invoice }}</td>
              <td>{{ row.total_returned_invoice }}</td>
              <td>{{ row.total }}</td>
              <td>{{ row.net_sale_cash_total_amount }}</td>
              <td>{{ row.net_sale_none_cash_total_amount }}</td>
              <td>{{ row.total_sales_remaining_amount }}</td>
              <td>{{ row.net_cash_received_amount }}</td>
              <td>{{ row.net_cash_expense_amount }}</td>
<!--              <td>{{ row.total_received_amount }}</td>-->
              <td>{{ row.net_cash_received_total_amount }}</td>
              <td>{{ row.net_none_cash_received_total_amount }}</td>
              <td>{{ row.total_received_amount }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr>
            <td colspan="8" style="text-align: start !important;">{{ $t('detailed_pos_shifts_transactions_report.summation') }}</td>
            <td>{{ dataTotalForExport.total_sales_invoice }}</td>
            <td>{{ dataTotalForExport.total_returned_invoice }}</td>
            <td>{{ dataTotalForExport.total }}</td>
            <td>{{ dataTotalForExport.net_sale_cash_total_amount }}</td>
            <td>{{ dataTotalForExport.net_sale_none_cash_total_amount }}</td>
            <td>{{ dataTotalForExport.total_sales_remaining_amount }}</td>
            <td>{{ dataTotalForExport.net_cash_received_amount }}</td>
            <td>{{ dataTotalForExport.net_cash_expense_amount }}</td>
<!--            <td>{{ dataTotalForExport.total_received_amount }}</td>-->
            <td>{{ dataTotalForExport.net_cash_received_total_amount }}</td>
            <td>{{ dataTotalForExport.net_none_cash_received_total_amount }}</td>
            <td>{{ dataTotalForExport.total_received_amount }}</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'DetailedPosShiftsTransactions'"></report-main-header>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-detailed-pos-shifts-transactions-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/v2/reports/pos/shifts/details',
      mainRouteDependency: 'base/dependency',
      items: [],
      data: [],
      dataTotal: [],
      dataForExport: [],
      dataTotalForExport: [],



      filters: {
        from_date: null,
        to_date: null,

        f_session_ids: null,
        f_devices_ids: null,
        f_shifts_ids: null,
        f_currencies_ids: null,
        f_inventories_ids: null,
        f_branches_ids: null,
        f_stores_ids: null,

        period: null,
        range: null,
        f_time_period: 0,
        financial_year: 0,
        order_by: null,

        group_by: 'session',
      },

      branches_ids: [],
      inventories_ids: [],
      stores_ids: [],
      stores: [],

      branches: [],
      status_list: [],
      inventories: [],
      currencies: [],
      device: null,
      devices: [],
      shift: null,
      shifts: [],
      period_date: ['day','week','month','year'],
      period_numbers: {
        day: 31,
        week: 4,
        month: 12,
        year: 10,
      },
      period_counter: [],
      currency_default_id: null,
      branch_default: null,
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
      f_by: 'name',
      inner_period: null,
      periodLabel: null,


    }
  },
  watch: {
    "filters.group_by": function (val) {
      if (val)
        this.doFilter();

    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.pos_reports"), route: '/reports/pos-reports-links'}, {title: this.$t("MENU.detailed_pos_shifts_transactions_report")}]);
    // this.getInventories();
    this.getBranches();
    this.getCurrencies();
    this.getShiftsAndDevices();
    this.getStores();
    this.getAuthUser();
  },
  methods: {
...cssStypeForPrintReport,
    doFilter() {
      this.page = 1;
      this.getData();
    },
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData();
    },
    resetFilter() {
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.order_by = null;
      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.financial_year = 0;

      this.filters.f_session_ids = null;
      this.filters.f_devices_ids = null;
      this.filters.f_shifts_ids = null;
      this.filters.f_currencies_ids = null;
      this.filters.f_inventories_ids = null;
      this.filters.f_branches_ids = null;
      this.filters.f_stores_ids = null;

      this.branches_ids = []
      this.inventories_ids = [];
      this.items_ids = [];
      this.device = null;
      this.shift = null;
      this.stores_ids = [];

      this.inner_period = null;

      this.getData();

    },

    getIds(related_with){
      switch (related_with) {
        case 'branches':
          this.filters.f_branches_ids = this.branches_ids.id;
          break;
        case 'inventories':
          this.filters.f_inventories_ids = this.inventories_ids.id;
          break;
        case 'device':
          this.filters.f_devices_ids = this.device ? this.device.id : null;
          break;
        case 'shift':
          this.filters.f_shifts_ids = this.shift ? this.shift.id : null;
          break;
        case 'stores':
          this.filters.f_stores_ids = this.stores_ids ? this.stores_ids.id : null;
          break;
      }

    },

    changeFinancialYear(){
      if (this.filters.financial_year == 1)
        this.filters.financial_year = 0;
      else
        this.filters.financial_year = 1;
    },
    changeIntervalType(action_type, range = null){
      if (this.filters.period == action_type){
        this.filters.period = null;
        this.filters.f_time_period = 0;
      }else{
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

    },

    dataReport() {
      this.setReportLog('excel', 'detailed pos shifts transactions report');
      return this.data;
    },
    getData() {
      let _limit = (this.page ? this.page : 1) * 25
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data = response.data.data.data;
        this.dataTotal = response.data.data.totals;
      });
    },
    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataForExport = response.data.data.data;
        this.dataTotalForExport = response.data.data.totals;
        this.convertTableToExcelReport(tableId);
      });
    },
    // getItems() {
    //     ApiService.get(this.mainRouteDependency + "/items").then((response) => {
    //         this.items = response.data.data;
    //     });
    // },

    getStores() {
      ApiService.get(this.mainRouteDependency + "/pos-stores-list").then((response) => {
        this.stores = response.data.data;
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getShiftsAndDevices() {
      ApiService.get(this.mainRouteDependency + "/pos_shifts_and_devices").then((response) => {
        this.shifts = response.data.data.pos_shift;
        this.devices = response.data.data.pos_device;
      });
    },
    getAuthUser() {
      ApiService.get(`/get_auth`).then((response) => {
        this.filters.f_currencies_ids = response.data.data.currency_id;
      });
    },
    printData(tableId) {
      this.setReportLog('pdf', 'detailed pos shifts transactions report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },

    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'detailed pos shifts transactions report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.detailed_pos_shifts_transactions_report') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
